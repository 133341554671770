@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_whiteHeader";
@use "../components/_stickyFooterSearch";

main{  

    .error-content{margin-top: 80px; padding-bottom: 200px;
        .error-header{text-align: center;
            h1{font-size: clamp(2.6rem, 4.6vw, 4.6rem); line-height: 140%;}
            h2{font-size: clamp(1.8rem, 2vw, 2rem); line-height: 140%; font-weight: 400; margin: 5px 0 10px; font-family: "Archivo"; letter-spacing: 0;}
            a{font-size: 1.6rem;}
        }
        .error-body{display: flex; margin: 40px auto 0;
            ul{flex:1; padding-inline: 50px;
                li{font-size: clamp(1.6rem, 1.6vw, 1.6rem);
                    a{font-size: 1.6rem; display: flex; gap:15px; align-items: center;
                        img{transform: translateY(1px); width: 7px;}
                    }
                    &:not(&:last-child){margin-bottom: 8px;}
                }
                &.go-to{border-right: 1px solid colors.$grey7; 
                    li{justify-content: flex-end; display: flex;}
                }
            }
            @include mixins.mq(800){flex-direction: column; margin-top: 0;
                ul{padding: 20px 0;
                    li{text-align: center;
                        a{justify-content: center;}
                    }
                    &.go-to{ border-right:none; border-bottom:1px solid colors.$grey7;
                        li{justify-content: center;}
                    }
                }
            }
        }
        .home-link{font-size: clamp(1.6rem, 1.6vw, 1.6rem); display: flex; gap:5px; justify-content: center; line-height: 140%; font-weight: 400; margin: 45px 0 0; font-family: "Archivo"; letter-spacing: 0; text-align: center;
            a{text-decoration: underline;}
        }
        @include mixins.mq(860){margin-top: 60px;}
        @include mixins.mq(500){margin-top: 40px;}
    }

    .columns.error{margin-top: 120px;
        @media(max-width: 930px){margin-top: 107px;}
        @media(max-width: 760px){margin-top: 80px;}
    }
    &:has(header.scrolled){
        .columns.error{margin-top: 70px;
            @media(max-width: 760px){margin-top: 56px;}
        }
    }
}